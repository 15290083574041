import gql from 'graphql-tag';

export const MigrateSpaceShortcutsToSmartLinksMutation = gql`
	mutation MigrateSpaceShortcutsToSmartLinksMutation(
		$spaceId: ID!
		$shortcutsList: [GraphQLSpaceShortcutsInput]!
	) {
		migrateSpaceShortcuts(spaceId: $spaceId, shortcutsList: $shortcutsList) {
			success
			errors {
				message
			}
			parentPageId
			smartLinksContentList {
				parentPageId
				spaceId
				contentId
				embedURL
				iconURL
				title
			}
		}
	}
`;
