import { useMutation } from 'react-apollo';
import type { JsonLd } from 'json-ld-types';

import { useSmartLinkContext } from '@atlaskit/link-provider';
import { extractTitle } from '@atlaskit/link-extractors';

import { extractIconUrl } from '@confluence/embed-utils/entry-points/extractIconUrl';

import { MigrateSpaceShortcutsToSmartLinksMutation } from './MigrateSpaceShortcutsToSmartLinksMutation.graphql';
import type {
	MigrateSpaceShortcutsToSmartLinksMutation as MigrateSpaceShortcutsToSmartLinksMutationResponse,
	MigrateSpaceShortcutsToSmartLinksMutationVariables,
} from './__types__/MigrateSpaceShortcutsToSmartLinksMutation.js';
import { SpaceShortcutsSpaceNavigationQuery } from './SpaceShortcutsSpaceNavigationQuery.graphql';

const MAX_TITLE_LENGTH = 60;

export const useMigrateShortcutsToSmartLinks = ({ spaceId, spaceKey, shortcutLinks }) => {
	const { connections } = useSmartLinkContext();

	const [migrateSpaceShortcuts] = useMutation<
		MigrateSpaceShortcutsToSmartLinksMutationResponse,
		MigrateSpaceShortcutsToSmartLinksMutationVariables
	>(MigrateSpaceShortcutsToSmartLinksMutation, {
		update: (cache) => {
			// to hide the shortcuts section after successful migration
			cache.writeQuery({
				query: SpaceShortcutsSpaceNavigationQuery,
				variables: { spaceKey },
				data: {
					spaceSidebarLinks: {
						quick: [],
					},
				},
			});
		},
	});

	const getSmartLinkTitle = ({ nickname, url, extractedTitle = '' }) => {
		// if the user had specified a shortcut "nickname", use that for the title instead.
		if (nickname && nickname != '') {
			return nickname;
		} else if (extractedTitle && extractedTitle != '') {
			return extractedTitle;
		}

		// deduping titles happens in the smart links APIs themselves, but we need to still truncate the titles before passing to the BE mutation
		return url.slice(0, MAX_TITLE_LENGTH);
	};

	type SpaceShortcutWithIconAndURL = {
		shortcutId: string;
		title: string;
		url: string;
		iconUrl: string | null;
		isPinnedPage: boolean;
	};

	const extractShortcutsTitlesAndIcons = (): Promise<SpaceShortcutWithIconAndURL[]> => {
		return Promise.all(
			shortcutLinks.reverse().map(async (shortcut) => {
				const url = shortcut.url;
				const shortcutId = shortcut.id;
				const nickname = shortcut.title;
				const isPinnedPage = shortcut.styleClass !== 'external_link';
				try {
					const details = await connections.client.fetchData(url);
					const data = (details && details.data) as JsonLd.Data.BaseData;

					if (!data) {
						return {
							shortcutId,
							title: getSmartLinkTitle({ nickname, url }),
							url,
							iconUrl: null,
							isPinnedPage,
						};
					}

					const embedIconUrl = extractIconUrl(data);
					const extractedTitle = extractTitle(data);
					return {
						shortcutId,
						title: getSmartLinkTitle({ nickname, url, extractedTitle }),
						url,
						iconUrl: embedIconUrl,
						isPinnedPage,
					};
				} catch (e) {
					// url is not supported (either Case 1. invalid URL or Case 2. not able to extract title due to reasons like auth)
					// We will still send these to the BE to handle:
					// Case 1. invalid URLs will be detected by the BE and deleted during migration
					// Case 2. Create smart links for Case 2 still, but they will show with smart links blank view
					return {
						shortcutId,
						title: getSmartLinkTitle({ nickname, url }),
						url,
						iconUrl: null,
						isPinnedPage,
					};
				}
			}),
		);
	};

	const migrateShortcutsToSmartLinks = async () => {
		const shortcutsList = await extractShortcutsTitlesAndIcons();
		return migrateSpaceShortcuts({
			variables: {
				spaceId,
				shortcutsList,
			},
		});
	};

	return { migrateShortcutsToSmartLinks };
};
