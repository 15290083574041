import type { FC } from 'react';
import React, { useEffect, useState, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import AkModalDialog, { ModalBody, ModalTitle, ModalHeader } from '@atlaskit/modal-dialog';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import Button from '@atlaskit/button/new';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { VIEW_PAGE } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager';
import type { FlagsStateContainer } from '@confluence/flags';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { MIGRATE_SHORTCUTS_EXPERIENCE } from '@confluence/experience-tracker';

export type MigrateShortcutsDialogType = {
	spaceKey: string;
	flags: FlagsStateContainer;
	migrateShortcutsToSmartLinks: () => Promise<any>;
	numOfShortcuts?: number;
};
export const SHORCUTS_MIGRATION_COMMUNITY_POST_LINK =
	'https://community.atlassian.com/t5/Confluence-articles/Product-update-Moving-Shortcuts-to-the-content-tree-as-Smart/ba-p/2700445#M11844';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ProgressBarWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div': {
		outline: 'none',
	},
});

const i18n = defineMessages({
	titleMigrating: {
		id: 'space-shortcuts.migrate-dialog.move-title',
		defaultMessage: 'Moving shortcuts...',
		description: 'Title of the dialog that appears when migrating shortcuts',
	},
	titleCompleted: {
		id: 'space-shortcuts.migrate-dialog.title-move-complete',
		defaultMessage: 'Move complete',
		description: 'Title of the dialog that appears when migrating shortcuts completes',
	},
	titleError: {
		id: 'space-shortcuts.migrate-dialog.title-error-move',
		defaultMessage: 'We couldn‘t move your shortcuts',
		description: 'Title of the dialog that appears when migrating shortcuts fails',
	},
	bodyInProgress: {
		id: 'space-shortcuts.migrate-dialog.body-in-progress',
		defaultMessage:
			'We’re converting existing shortcuts to Smart Links and integrating them with the rest of your content. This should only take a moment.',
		description: 'Copy of the dialog that appears when migrating shortcuts',
	},
	bodyCompleted: {
		id: 'space-shortcuts.migrate-dialog.body-finished-move',
		defaultMessage: 'We’ve finished moving your shortcuts. Refresh the page to continue.',
		description: 'Copy of the dialog that appears when migrating shortcuts completes',
	},
	bodyError: {
		id: 'space-shortcuts.migrate-dialog.body-error',
		defaultMessage:
			'We’ve run into a temporary snag, but it should be resolved shortly. Wait a moment, then try again.',
		description: 'Copy of the dialog that appears when migrating shortcuts errors',
	},
	refresh: {
		id: 'space-shortcuts.migrate-dialog.refresh',
		defaultMessage: 'Refresh page',
		description: 'Refresh button text in shortcuts migration dialog',
	},
	close: {
		id: 'space-shortcuts.migrate-dialog.close',
		defaultMessage: 'Close',
		description: 'Close button text in shortcuts migration dialog',
	},
	successFlagTitle: {
		id: 'space-shortcuts.migrate-dialog.success-flag-title-move',
		defaultMessage: 'We’ve finished moving your shortcuts.',
		description: 'Title of the success flag after migrating shortcuts',
	},
	learnMoreSuccessFlagAction: {
		id: 'space-shortcuts.migrate-dialog.success-flag.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more action on success flag after successful shortcuts migration',
	},
});

const contentWrapperStyles = xcss({
	marginBottom: 'space.300',
});

const descriptionWrapperStyles = xcss({
	marginBottom: 'space.400',
});

export const MigrateShortcutsDialog: FC<
	DialogsContainerProps & MigrateShortcutsDialogType & { experienceTracker: ExperienceTrackerAPI }
> = ({
	onClose,
	flags,
	spaceKey,
	migrateShortcutsToSmartLinks,
	experienceTracker,
	numOfShortcuts = 0,
}) => {
	const { push } = useRouteActions();
	const [successfulMigration, setSuccessfulMigration] = useState(false);
	const [migrationError, setMigrationError] = useState<Error | null>(null);
	const [migratedShortcutsParentPageId, setMigratedShortcutsParentPageId] = useState<string | null>(
		null,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleLearnMoreClick = () => {
		window.open(SHORCUTS_MIGRATION_COMMUNITY_POST_LINK, '_blank');
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'shortcutsMigrationSuccessFlag',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'learnMore',
			},
		}).fire();
	};

	const handleOnCloseAfterSuccess = () => {
		onClose();
		flags.showSuccessFlag({
			title: <FormattedMessage {...i18n.successFlagTitle} />,
			actions: [
				{
					content: <FormattedMessage {...i18n.learnMoreSuccessFlagAction} />,
					onClick: () => handleLearnMoreClick(),
				},
			],
		});

		const url = VIEW_PAGE.toUrl({
			spaceKey,
			contentId: migratedShortcutsParentPageId,
		});
		push(url);
	};

	useEffect(() => {
		migrateShortcutsToSmartLinks()
			.then(({ data }) => {
				setSuccessfulMigration(true);
				setMigratedShortcutsParentPageId(data.migrateSpaceShortcuts.parentPageId);
				experienceTracker.succeed({
					name: MIGRATE_SHORTCUTS_EXPERIENCE,
				});
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'migrated',
						actionSubject: 'shortcuts',
						source: 'shortcutsMigration',
						attributes: {
							numOfShortcuts,
						},
					},
				}).fire();
			})
			.catch((error) => {
				experienceTracker.stopOnError({
					name: MIGRATE_SHORTCUTS_EXPERIENCE,
					error,
				});
				setMigrationError(error || 'shortcuts migration failed');
			});
	}, [migrateShortcutsToSmartLinks, experienceTracker, createAnalyticsEvent, numOfShortcuts]);

	const getModalTitle = () => {
		if (migrationError) {
			return <FormattedMessage {...i18n.titleError} />;
		} else if (successfulMigration) {
			return <FormattedMessage {...i18n.titleCompleted} />;
		} else {
			return <FormattedMessage {...i18n.titleMigrating} />;
		}
	};

	const getModalBody = () => {
		if (migrationError) {
			return (
				<Fragment>
					<ErrorDisplay error={migrationError} />
					<Box xcss={descriptionWrapperStyles}>
						<FormattedMessage {...i18n.bodyError} />
					</Box>
					<Flex justifyContent="end">
						<Button appearance="primary" onClick={onClose}>
							<FormattedMessage {...i18n.close} />
						</Button>
					</Flex>
				</Fragment>
			);
		} else if (successfulMigration) {
			return (
				<Fragment>
					<Box xcss={descriptionWrapperStyles}>
						<FormattedMessage {...i18n.bodyCompleted} />
					</Box>
					<Flex justifyContent="end">
						<Button appearance="primary" onClick={handleOnCloseAfterSuccess}>
							<FormattedMessage {...i18n.refresh} />
						</Button>
					</Flex>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Box xcss={descriptionWrapperStyles}>
						<FormattedMessage {...i18n.bodyInProgress} />
					</Box>
					<ProgressBarWrapper>
						<SuccessProgressBar ariaLabel="Migrating shortcuts" isIndeterminate />
					</ProgressBarWrapper>
				</Fragment>
			);
		}
	};

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<AkModalDialog onClose={onClose} width="small">
				<ModalHeader>
					<ModalTitle>{getModalTitle()}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Box xcss={contentWrapperStyles}>{getModalBody()}</Box>
				</ModalBody>
			</AkModalDialog>
		</ErrorBoundary>
	);
};
