import type { FC } from 'react';
import React, { useMemo, useCallback, useContext, useState } from 'react';
import { styled } from '@compiled/react';
import { useIntl, defineMessages } from 'react-intl-next';

import {
	Card,
	TitleBlock,
	ActionName,
	SmartLinkPosition,
	SmartLinkDirection,
	loadingPlaceholderClassName,
	SmartLinkSize,
} from '@atlaskit/smart-card';
import { CardSSR } from '@atlaskit/smart-card/ssr';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import { N20, N30, N700 } from '@atlaskit/theme/colors';
import Popup from '@atlaskit/popup';
import Blanket from '@atlaskit/blanket';
import type { LinkPickerPlugin } from '@atlaskit/link-picker';
import { LazyLinkPicker } from '@atlaskit/link-picker/lazy';
import { token } from '@atlaskit/tokens';

import { AtlassianLinkPickerPlugin } from '@atlassian/link-picker-atlassian-plugin';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSearchProvider } from '@confluence/editor-features';
import { START_TOUCH } from '@confluence/navdex';

import type { SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType } from './__types__/SpaceShortcutsSpaceNavigationQuery';
import { useEditShortcut } from './useShortcuts';

export const isPageSSRd = () => {
	return process.env.REACT_SSR || window.__SSR_RENDERED__;
};

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ShortcutsItemContainer = styled.div`
	cursor: default;
	position: relative;
	border-radius: 3px;
	max-height: 40px;
	&:hover,
	&:focus,
	&:focus-within {
		background-color: ${token('color.background.neutral.subtle.hovered', N30)};
	}
	.actions-button-group {
		opacity: 0;
	}

	&[data-is-menu-open='true'] {
		background-color: ${token('color.background.neutral.subtle.pressed', N30)};
		.actions-button-group {
			opacity: 100%;
			button {
				background-color: ${token('color.background.neutral.bold', N700)};
				color: ${token('color.text.subtlest', N20)} !important;
			}
		}
	}

	&:hover {
		.actions-button-group {
			opacity: 100%;
			transition: opacity ease-in 0.3s;
			flex-shrink: 0;
		}
	}

	@keyframes loading-icon {
		from {
			background-position: -20px 0;
		}
		to {
			background-position: 20px 0;
		}
	}

	${() =>
		isPageSSRd()
			? ''
			: `& .${loadingPlaceholderClassName} {
    display: inline-block;
    width: 100%;
    height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0px 42px 0 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    text-decoration: none;
    font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Noto Sans",
      Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif;
    font-wight: 400;
    font-size: 14px;
    line-height: 40px;
    align-items: center;
    letter-spacing: -0.003em;
    &::before {
      content: "";
      display: inline-block;
      width: 22.3906px;
      height: 22.3906px;
      position: relative;
      top: 6px;
      flex-shrink: 0;
      margin-right: 16px;
      user-select: none;
      background: var(--ds-skeleton-subtle, #f6f7f8);
      background-image: linear-gradient(
        to right,
        var(--ds-skeleton-subtle, #f6f7f8) 0%,
        var(--ds-skeleton, #edeef1) 20%,
        var(--ds-skeleton-subtle, #f6f7f8) 40%,
        var(--ds-skeleton-subtle, #f6f7f8) 100%
      );
      background-repeat: no-repeat;
      background-size: 280% 100%;
      border-radius: 2px;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: loading-icon;
      animation-timing-function: linear;
    }
  }`}

	span[data-testid="smart-element-link-tooltip--container"] {
		width: 100%;
		display: flex;
		flex: 1 1 0px;
	}

	&:hover {
		background-color: ${token('color.background.neutral', N20)};
	}

	& div[data-smart-link-container='true'] {
		display: flex;
		height: 40px;
		justify-content: center;
		padding: 0px 10px;
		[data-smart-element-icon='true'],
		[data-smart-element-icon='true'] span,
		[data-smart-element-icon='true'] svg,
		[data-smart-element-icon='true'] img {
			height: 16px;
			min-height: 16px;
			width: 16px;
			min-width: 16px;
		}
		height: 36px;
		padding: 0px ${token('space.050', '4px')} 0px ${token('space.150', '12px')};
		div[data-smart-block='true'] {
			height: 40px;
			gap: ${token('space.100', '8px')};
			align-items: center;
			img,
			span[role='img'],
			span[role='img'] svg,
			div[data-smart-element-icon='true'],
			span[data-testid='deleteAction-icon'] {
				height: 1.5rem;
				width: 1.5rem;
				min-height: 1.5rem;
				max-height: 1.5rem;
				min-width: 1.5rem;
				max-width: 1.5rem;
			}

			button {
				svg {
					color: var(--ds-text);
				}
			}
		}
	}

	div.actions-button-group {
		line-height: 24px;
		button {
			padding: 0;
			height: 24px;
			width: 24px;
			min-width: 24px;
		}
	}
	a,
	a:visited,
	a:focus,
	a:hover,
	a:active {
		text-decoration: none;
		color: var(--ds-text);
	}
	a:visited {
		color: var(--ds-text);
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupTrigger = styled.span({
	float: 'right',
	maxWidth: 0,
	maxHeight: 0,
	visibility: 'hidden',
	overflow: 'hidden',
});

const i18n = defineMessages({
	shortcutsQuickActionsRemoveButton: {
		id: 'space-shortcuts.shortcuts.quick-actions.remove',
		defaultMessage: 'Remove',
		description: 'Text for remove button',
	},
});

type ShortcutsItemProps = {
	item: SpaceNavigationQueryShortcutsType;
	spaceId: string | null;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	isSpaceAdmin: boolean;
	links: SpaceNavigationQueryShortcutsType[];
	deleteShortcut: (item: SpaceNavigationQueryShortcutsType) => void;
};

//not exported from smartCard for some reason
type AnchorTarget = '_blank' | '_self' | '_top' | '_parent';

// string begins with 1+ characters other than "/", has a "/", and is followed by one character other than "/"
// OR string begins with "/", is followed by 1 character other than "/", ends with any # of any character
// while ignoring capitalization
const relativeUrlMatch = new RegExp(`^[^\/]+\/[^\/].*$|^\/[^\/].*$`, 'i');

// adding domain to relative confluence URLs so smart links don't error
export const getFullUrl = (url, isExternal) => {
	if (url === null) {
		return url;
	}
	if (!isExternal && relativeUrlMatch.test(url)) {
		return window.location.origin + url;
	}
	// edge case that doesn't match regexp
	if (url.match(`${window.location.protocol}///wiki`)) {
		return window.location.origin + url.replace(`${window.location.protocol}//`, '');
	}
	return url;
};

export const ShortcutsItemComponent: FC<ShortcutsItemProps & WithFlagsProps> = ({
	flags,
	item,
	spaceId,
	createAnalyticsEvent,
	isSpaceAdmin,
	links,
	deleteShortcut,
}) => {
	const intl = useIntl();
	const CardComponent = isPageSSRd() ? CardSSR : Card;
	let { url, styleClass } = item;
	url = url || ('' as string);
	const { title = '', id } = item;
	//styleClass is the old way of knowing if a link was internal or external
	styleClass = styleClass || '';
	const key = id.concat('-', url || '');

	const { matchRoute } = useContext(RoutesContext);
	let target: AnchorTarget = '_blank',
		navdexPointType: 'navigationSessionStartTouch' | null = null;
	try {
		const isRelativeUrl = matchRoute(url);
		const includesHostName = url.includes(window.location.hostname);
		const containsExternalStyleClass = styleClass.indexOf('external_link') >= 0;
		//now we also check if the link is relative or contains the pathname, falls back to styleclass
		const isExternal = isRelativeUrl
			? false
			: includesHostName
				? false
				: containsExternalStyleClass;
		url = getFullUrl(url, isExternal) || '';
		target = isExternal ? '_blank' : '_self';
		navdexPointType = !isExternal ? START_TOUCH : null;
	} catch {
		// eslint-disable-next-line no-console
		console.warn('malformed URL:', url);
		//prefer to show a different UI when the link is bad, letting the user know they should update the shortcut...
		//however the smartcard format doesn't really have a way to do this.
	}

	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

	const handleClose = useCallback(() => {
		setIsEditOpen(false);
	}, [setIsEditOpen]);

	const openEdit = useCallback(() => {
		setIsEditOpen(!isEditOpen);
	}, [isEditOpen, setIsEditOpen]);

	const handleSubmit = useEditShortcut(links, flags, spaceId);

	const onSubmit = useCallback(
		(href: string, displayText: string | null) => {
			handleSubmit({
				id,
				url: href,
				title: displayText || '',
			});
			handleClose();
		},
		[handleClose, handleSubmit, id],
	);

	const searchProvider = useSearchProvider();
	const plugins: [LinkPickerPlugin] = useMemo(
		() => [
			new AtlassianLinkPickerPlugin({
				searchProvider,
				activityClientEndpoint: `${window.location.origin}/gateway/api/graphql`,
			}),
		],
		[searchProvider],
	);

	const location = 'confluenceShortcuts';

	const sendShortcutClickedAnalytics = (navdexPointType) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'spaceShortcut',
				source: 'containerNavigation',
				containerType: 'space',
				containerId: spaceId,
				attributes: {
					navVersion: '3',
					...(navdexPointType && { navdexPointType }),
					location,
				},
			},
		}).fire();
	};

	/* eslint-disable jsx-a11y/no-autofocus */
	// Popup was stealing focus away from the LinkPicker autofocus
	return (
		<ShortcutsItemContainer key={key} data-is-menu-open={isActionMenuOpen || isEditOpen}>
			<CardComponent
				testId="shortcut-item"
				appearance="inline"
				url={url || ''}
				placeholder={title || undefined}
				onClick={sendShortcutClickedAnalytics(navdexPointType)}
				ui={{
					hideBackground: true,
					hideElevation: true,
					hidePadding: true,
					clickableContainer: true,
					size: SmartLinkSize.Large,
				}}
			>
				<TitleBlock
					direction={SmartLinkDirection.Horizontal}
					maxLines={1}
					position={SmartLinkPosition.Center}
					text={title || undefined}
					anchorTarget={target}
					hideTitleTooltip
					actions={
						isSpaceAdmin
							? [
									{
										name: ActionName.EditAction,
										onClick: openEdit,
										iconPosition: 'before',
										testId: 'editAction',
									},
									{
										name: ActionName.DeleteAction,
										onClick: () => deleteShortcut(item),
										iconPosition: 'before',
										testId: 'deleteAction',
										content: intl.formatMessage(i18n.shortcutsQuickActionsRemoveButton),
									},
								]
							: undefined
					}
					showActionOnHover
					onActionMenuOpenChange={({ isOpen }) => {
						setIsActionMenuOpen(isOpen);
					}}
				/>
			</CardComponent>
			<Popup
				autoFocus={false}
				isOpen={isEditOpen}
				onClose={() => handleClose()}
				content={(props) => (
					<div>
						<LazyLinkPicker
							{...props}
							plugins={plugins}
							onSubmit={({ url, displayText }) => {
								onSubmit(url, displayText);
							}}
							onCancel={handleClose}
							url={url || ''}
							displayText={title || ''}
						/>
					</div>
				)}
				placement="bottom-start"
				offset={[-42, 4]}
				trigger={(triggerProps) => (
					<PopupTrigger {...triggerProps}>&nbsp;This trigger is hidden</PopupTrigger>
				)}
			/>

			<Blanket
				data-testid="space-shortcuts-actions-blanket"
				isTinted={false}
				shouldAllowClickThrough={!isEditOpen && !isActionMenuOpen}
				onBlanketClicked={() => {
					setIsActionMenuOpen(false);
					setIsEditOpen(false);
				}}
			/>
		</ShortcutsItemContainer>
	);
};

export const ShortcutsItem = withFlags(ShortcutsItemComponent);
